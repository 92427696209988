import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import FadeIn from '../../molecules/Transitions/FadeIn'
import { useConfigAtDepth, useStatusInfo } from './hooks'
import StatusDisplay from './StatusDisplay'
import { useStatusReportContext } from './statusReportContext'
import DisplayName from './DisplayName'
import StatusReportItemActions from './StatusReportItemActions'
import { useInternalReportContext } from './InternalStatusReportContext'
import PanelTrigger from './PanelTrigger'
import StatusReportItemActionMenu from './StatusReportItemActionMenu'

const summaryClasses = { root: '__summary-root', content: '__summary-content' }
const summaryClassesExpanded = { root: '__summary-root-expanded', content: '__summary-content' }
const detailsClasses = { root: '__details-root' }

const useStyles = makeStyles((theme) => ({
  overrides: {
    fontSize: ({ fontSize, defaultFontSize }) => fontSize || defaultFontSize || '12px'
  }
}))

/* eslint-disable react/prop-types */
function Header ({ item, statuses, depth, showOrdinal, showStatus, config, fontSize, style }) {
  const classes = useStyles({ depth, fontSize, defaultFontSize: '22px' })
  const statusInfo = useStatusInfo(item.status, statuses)

  return (
    <FadeIn className='__header-wrapper' style={style}>
      <div className={clsx('__header', classes.overrides)}>
        {showStatus ? (
          <div className='__status'>
            <StatusDisplay statusInfo={statusInfo} />
          </div>
        ) : (
          <StatusReportItemActionMenu item={item} noHide />
        )}
        {showOrdinal ? (
          <div className='__ordinal'>{item.ordinal}</div>
        ) : null}
        <DisplayName className='__display-name' item={item} />
      </div>
      <div className='__header-chldren'>
        {item.items.map(i => (
          <StatusReportItem
            key={i.codeName}
            item={i}
            depth={depth + 1}
            statuses={statuses}
            config={config}
            parent={item}
          />
        ))}
      </div>
    </FadeIn>
  )
}

function Group ({ item, statuses, depth, showOrdinal, showStatus, config, fontSize, style, defaultExpand }) {
  const classes = useStyles({ depth, fontSize })
  const hasChildren = useMemo(() => item.items?.length > 0, [item])
  const statusInfo = useStatusInfo(item.status, statuses)
  const { getExpandState, toggleExpand } = useInternalReportContext()

  if (!hasChildren) {
    return (
      <FadeIn className='__group-wrapper' style={style}>
        <div className={clsx('__group', classes.overrides)}>
          {showStatus ? (
            <div className='__status'>
              <StatusDisplay statusInfo={statusInfo} />
            </div>
          ) : (
            <StatusReportItemActionMenu item={item} />
          )}
          {showOrdinal ? (
            <div className='__ordinal'>{item.ordinal}</div>
          ) : null}
          <DisplayName className='__display-name' item={item} />
        </div>
      </FadeIn>
    )
  }

  const expandState = getExpandState(item, defaultExpand)
  return (
    <FadeIn className='__group-wrapper' style={style}>
      <Accordion
        className='__accordion'
        expanded={expandState}
        onChange={(e) => toggleExpand(e, item)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={expandState ? summaryClassesExpanded : summaryClasses}>
          <div className={clsx('__group', classes.overrides)}>
            {showStatus ? (
              <div className='__status'>
                <StatusDisplay statusInfo={statusInfo} />
              </div>
            ) : (
              <StatusReportItemActionMenu item={item} />
            )}
            {showOrdinal ? (
              <div className='__ordinal'>{item.ordinal}</div>
            ) : null}
            <DisplayName className='__display-name' item={item} />
          </div>
        </AccordionSummary>
        {hasChildren ? (
          <AccordionDetails classes={detailsClasses}>
            <div className='__group-children'>
              {item.items.map(i => (
                <StatusReportItem
                  key={i.codeName}
                  item={i}
                  depth={depth + 1}
                  statuses={statuses}
                  config={config}
                  parent={item}
                />
              ))}
            </div>
          </AccordionDetails>
        ) : null}
      </Accordion>
    </FadeIn>
  )
}

function Detail ({ item, statuses, depth, showOrdinal, showStatus, parent, fontSize, config, style, defaultExpand }) {
  const classes = useStyles({ depth, fontSize })
  const hasChildren = useMemo(() => item.items?.length > 0 && !item.options?.length, [item])
  const hasOptions = useMemo(() => item.allowOptions && item.options?.length > 0, [item])
  const statusInfo = useStatusInfo(item.status, statuses)
  const { getExpandState, toggleExpand, reportOptions } = useInternalReportContext()

  if (!hasChildren) {
    return (
      <>
        <FadeIn className={clsx('__detail', classes.overrides)} style={style}>
          {showStatus ? (
            <PanelTrigger className='__status' item={item} parent={parent}>
              <StatusDisplay statusInfo={statusInfo} />
            </PanelTrigger>
          ) : null}
          {showOrdinal ? (
            <div className='__ordinal'>{item.ordinal}</div>
          ) : null}
          <DisplayName className='__display-name' item={item} parent={parent} />
          <StatusReportItemActions item={item} parent={parent} reportOptions={reportOptions} />
        </FadeIn>
        {hasOptions ? (
          <FadeIn className='__option-container' style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='__option-label'>{`${item.optionLabel || 'Recommendation'}(s):`}</div>
            <ol>
              {item.options.map(opt => (
                <li className='__option' key={`statusReportItemOption_${opt.statusReportItemOptionId}`}>{opt.text}</li>
              ))}
            </ol>
          </FadeIn>
        ) : null}
      </>
    )
  }

  const expandState = getExpandState(item, defaultExpand)
  return (
    <FadeIn className={clsx('__detail-wrapper', classes.overrides)} style={style}>
      <Accordion
        className={expandState ? '__detail-accordion-expanded' : '__detail-accordion'}
        expanded={expandState}
        onChange={(e) => toggleExpand(e, item)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={expandState ? summaryClassesExpanded : summaryClasses}>
          {showStatus ? (
            <PanelTrigger className='__status' item={item} parent={parent}>
              <StatusDisplay statusInfo={statusInfo} />
            </PanelTrigger>
          ) : null}
          {showOrdinal ? (
            <div className='__ordinal'>{item.ordinal}</div>
          ) : null}
          <DisplayName className='__display-name' item={item} parent={parent} />
          <StatusReportItemActions item={item} parent={parent} reportOptions={reportOptions} />
        </AccordionSummary>
        {hasChildren ? (
          <AccordionDetails classes={detailsClasses}>
            <div className='__detail-children'>
              {item.items.map(i => (
                <StatusReportItem
                  key={i.codeName}
                  item={i}
                  depth={depth + 1}
                  statuses={statuses}
                  config={config}
                  parent={item}
                />
              ))}
            </div>
          </AccordionDetails>
        ) : null}
      </Accordion>
    </FadeIn>
  )
}

function StatusReportItem ({ item, statuses, depth, config, parent }) {
  const itemConfig = useConfigAtDepth(config, depth)
  const { filter } = useStatusReportContext()

  if (!filter(depth)(item.status)) {
    return null
  }

  if (item.hidden) return null

  if (itemConfig.displayAs === 'header') {
    return (
      <Header
        item={item}
        depth={depth}
        statuses={statuses}
        style={itemConfig.style}
        showOrdinal={itemConfig.showOrdinal}
        showStatus={itemConfig.showStatus}
        defaultExpand={itemConfig.defaultExpand}
        fontSize={itemConfig.fontSize}
        config={config}
        parent={parent}
      />
    )
  }

  if (itemConfig.displayAs === 'group') {
    return (
      <Group
        item={item}
        depth={depth}
        statuses={statuses}
        style={itemConfig.style}
        showOrdinal={itemConfig.showOrdinal}
        showStatus={itemConfig.showStatus}
        defaultExpand={itemConfig.defaultExpand}
        fontSize={itemConfig.fontSize}
        config={config}
        parent={parent}
      />
    )
  }

  if (itemConfig.displayAs === 'detail') {
    return (
      <Detail
        item={item}
        depth={depth}
        statuses={statuses}
        style={itemConfig.style}
        showOrdinal={itemConfig.showOrdinal}
        showStatus={itemConfig.showStatus}
        defaultExpand={itemConfig.defaultExpand}
        fontSize={itemConfig.fontSize}
        config={config}
        parent={parent}
      />
    )
  }

  return null
}

StatusReportItem.propTypes = {
  item: PropTypes.shape({
    displayName: PropTypes.string,
    reportTemplateItemId: PropTypes.number,
    status: PropTypes.number,
    ordinal: PropTypes.number,
    items: PropTypes.array,
    threadKey: PropTypes.string,
    attachmentCount: PropTypes.number,
    hidden: PropTypes.bool,
    isCustom: PropTypes.bool
  }),
  statuses: PropTypes.array,
  depth: PropTypes.number,
  config: PropTypes.object,
  parent: PropTypes.shape({
    displayName: PropTypes.string
  })
}

StatusReportItem.defaultProps = {
  depth: 0
}

export default StatusReportItem
